import React, { useEffect, useState } from 'react';
import { AccountProfileWrapper } from '../src/';
import projectNames from './configs/projectNames';
import resources from '../src/assets/locale';
import { ThemeProvider } from '@veneer/theme';
import TokenProvider from '../src/contexts/TokenProvider';
import { ShellRootProvider } from '../src/contexts/ShellRoot';
import {
  InterfacesType,
  ShellRootComponentProps,
  TranslatorFunctionType
} from '../src/types/shell';

export default function Root({ useToast, ...props }: ShellRootComponentProps) {
  // You can find all Shell´s props here. See "../src/types/shell" for more details.
  const [t, setT] = useState<TranslatorFunctionType>();
  // You can find all Shell´s props here. See "src/types/shell" for more details.
  const { v1, v2 } = window.Shell as InterfacesType;
  const interfaces = {
    v1,
    v2
  };

  // Create a translator function by providing the resource files
  useEffect(() => {
    v1.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v));
  }, [v1]);

  // Wait for the translator function creation to show the content
  if (!t) {
    return null;
  }
  // override props value with the one set at the manifest
  props.stack = interfaces.v1.app.getPortalStack();
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <ThemeProvider
        mode="light"
        shape="round"
        density="standard"
      >
        <ShellRootProvider {...{ interfaces, t, useToast }}>
          <TokenProvider
            authService={interfaces.v1.authProvider}
            authToken={interfaces.v1.authToken}
            sessionInterface={interfaces.v1.sessionInterface}
          >
            <AccountProfileWrapper />
          </TokenProvider>
        </ShellRootProvider>
      </ThemeProvider>
    </section>
  );
}
