import { GoogleTagManager } from '../assets/static/googleTagManager';
export const GTMEvents = {
  EDIT_PROFILE: 'user_edit_profile'
};
// Event data layer for Google Tag Manager
const eventDataLayer = (stack: string, { event, clientID, ...args }) => {
  GoogleTagManager.init(stack, {
    event: event,
    date: new Date().toUTCString(),
    clientID: clientID,
    ...args
  });
};

export default eventDataLayer;
